import Vue from 'vue';
import Router from 'vue-router';
import store from "./store/index"
import Login from "./components/Login.vue"
import Home from "./components/Home.vue"
import MyBooks from "./components/Student/MyBooks.vue"
import Lesson from "./components/Student/Lesson.vue"
import AllBooks from "./components/Teacher/AllBooks.vue"
import Report from "./components/Teacher/Report.vue"

Vue.use(Router);

const router = new Router({
    // mode: "history",
    routes: [
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            name: 'home',
            path: '/',
            component: Home,
        },
        {
            name: 'all-books',
            path: '/all-books',
            component: AllBooks,
        },
        {
            name: 'report',
            path: '/report',
            component: Report,
        },
        {
            name: 'my-books',
            path: '/my-books',
            component: MyBooks,
        },
        {
            name: 'lesson',
            path: '/lesson',
            component: Lesson,
        },
    ],
});

export default router;

router.beforeEach((to, from, next) => {
    const auth = store.getters.isAuthenticated;
    if (auth === null && !(to.name === 'login')) {
        router.push('/login').catch(() => {});
        next();
    }
    next();
});
