<template>
  <div class="">
    <navbar></navbar>
    <loading v-if="ready"></loading>
    <div v-else class="container mx-auto mt-5 max-w-7xl px-4 sm:px-6">

      <div class="grid grid-cols-4 sm:grid-cols-8 gap-4">

        <div class="col-span-2">
          <label for="selectClass" class="block mb-2 text-sm text-white">Class List:</label>
          <select
              id="selectClass"
              v-model="selectClass"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="">Select a class...</option>
            <option
                v-for="(classList, i) in organizations"
                :key="i"
                :value="classList.name">
              {{ classList.name }}
            </option>
          </select>
        </div>

        <div class="col-span-2">
          <label for="selectClass" class="block mb-2 text-sm text-white">Book List:</label>
          <select
              id="selectBook"
              v-model="selectBook"
              :disabled="books.length === 0"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="">Select a book...</option>
            <option
                class="m-4"
                v-for="(book, i) in books"
                :key="i"
                :value="book.id">
              {{ book.title }}
            </option>
          </select>
        </div>

<!--        <div class="col-span-2" v-if="this.userGrades.find(i => i == 99)">-->
<!--          <label for="selectClass" class="block mb-2 text-sm text-white">Unit List:</label>-->
<!--          <select-->
<!--              id="selectUnit"-->
<!--              v-model="selectUnit"-->
<!--              :disabled="units.length === 0"-->
<!--              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">-->
<!--            <option value="">Select a unit...</option>-->
<!--            <option-->
<!--                class="m-4"-->
<!--                v-for="(unit, i) in units"-->
<!--                :key="i"-->
<!--                :value="unit.id">-->
<!--              {{ unit.name + ' - ' + unit.description }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->

        <div class="col-span-2 flex items-end">
          <button
              @click="goReport"
              :disabled="selectClass === '' || selectBook === ''"
              class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 rounded-lg text-sm px-5 py-2.5 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-500 hover:cursor-pointer">
            Get Report
          </button>
        </div>

      </div>

      <div
          class="container my-10"
          v-if="students != null && students.length > 0">
        <table class="w-full shadow overflow-hidden sm:rounded-lg">
          <thead class="bg-pink-500">
          <tr>
            <th
                scope="col"
                class="p-3 text-center text-sm text-white uppercase whitespace-nowrap">
              &nbsp;
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-left text-sm text-white uppercase whitespace-nowrap">
              Student Name
            </th>
            <th
                scope="col"
                v-for="(n, index) in units"
                :key="index"
                class="px-6 py-3 text-sm text-white uppercase whitespace-nowrap">
              {{ n.name }}
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(item, index) in students" :key="index">
            <td class="p-3 text-gray-500 text-center text-xs">{{ index + 1 }}.</td>
            <td class="px-6 py-3 text-gray-500">
              {{ item.name }}
            </td>
            <td
                v-for="(n, index) in units"
                :key="index"
                class="px-6 py-3 text-center text-gray-500 whitespace-nowrap">
              <div v-if="userGrades.find(i => i == 99)">
                {{ item.books.filter(i => i.bookID === selectBook && i.unitID === n.id).length > 0
                  ? item.books.filter(t => t.bookID === selectBook && t.unitID === n.id)[0].unitTotalTrueItemCount + ' / ' + item.books.filter(t => t.bookID === selectBook && t.unitID === n.id)[0].unitTotalItemCount
                  : '-'
                }}
                <!-- Math.floor((100 * item.books.filter(t => t.bookID === selectBook && t.unitID === n.id)[0].unitTotalTrueItemCount) / item.books.filter(t => t.bookID === selectBook && t.unitID === n.id)[0].unitTotalItemCount) -->
              </div>
              <div v-else>
              {{
                item.books.filter(i => i.bookID === selectBook && i.unitID === n.id).length > 0
                    ? '%' + Math.floor(item.books.filter(t => t.bookID === selectBook && t.unitID === n.id)[0].score)
                    : '-'
              }}
              </div>
            </td>
            <!--
              {{ item.books.filter(t => t.bookID === selectBook && t.unitID === n.id) }}
            -->
          </tr>
          </tbody>
        </table>
      </div>

      <div class="container mt-10" v-else-if="students != null">
        <div class="text-center text-2xl bg-white text-red-600 rounded-lg shadow-lg p-8">
          No result!
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import navbar from "../Navbar/Navbar.vue";
import loading from "../Loading/Loading.vue";
import teacherServices from "@/services/teacher";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      ready: false,
      students: null,
      organizations: null,
      units: [],
      selectClass: "",
      selectBook: "",
      selectUnit: "",
      books: [],
      userGrades: [],
    };
  },
  components: {
    navbar,
    loading,
  },
  computed: {
    ...mapGetters(["getUserDetail"]),
  },
  async mounted() {
    // this.selectClass = "";
    // this.selectBook = "";
    // this.selectUnit = "";
    let grades = [];
    this.organizations = this.getUserDetail.organizations.filter(i => i.type === "grade");
    this.organizations.filter(function (s) {
      grades.push(s.name.split("-")[0]);
    });
    this.userGrades = grades.filter((x, i, a) => a.indexOf(x) === i)
  },
  watch: {
    async selectClass(val) {
      this.ready = true
      this.books = await teacherServices.getBooks();
      this.books = this.books.filter(x => x.grade === parseInt(val));
      this.selectBook = "";
      this.selectUnit = "";
      this.units = [];
      this.students = null;
      this.ready = false
    },
    selectBook(val) {
      if (val) {
        this.units = this.books.filter(x => x.id === this.selectBook)[0].units;
        this.selectUnit = "";
      } else {
        this.units = []
      }
      this.students = null;
    },
  },
  methods: {
    async goReport() {
      // this.ready = true;
      this.students = null;
      // seçili sınıfın isminden id'si bulunuyor
      const class_id = await this.getUserDetail.organizations.filter(
          i => i.type === "grade" && i.name === this.selectClass
      )[0].id;
      this.students = await teacherServices.getOrganizationStudents(
          class_id,
          this.selectBook,
          this.selectUnit,
      );
      await this.students.forEach(element => {
        element.units = _.sortBy(element.books.filter(
            x => x.bookID === this.selectBook), 'unitID'
        );
      });
      // this.units = this.books.filter(x => x.id === this.selectBook)[0].units;
      this.ready = false;
    }
  },
};
</script>
<style></style>
