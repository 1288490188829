<template>
  <div id="content" ref="content">
    <nav role="navigation">
        <div class="menu">
            <div class="sizing-btns" @click="fit()">{{ isFit ? 'Resize' : 'Resize' }}</div>
            <div v-if="userGrade === 104 || userGrade === 105" class="save-btn" @click="saveAndExit()">Exit</div>
            <div v-else class="save-btn" @click="saveAndExit()">Save & Exit</div>
        </div>
    </nav>
    <iframe
    v-if="isLoaded"
    id="iframe"
    ref="iframe"
    allowfullscreen="allowfullscreen"
    scrolling="no"
    :style="[styleFrame, {opacity: isLoaded ? 1 : 0}]"
    :src="iframeSrc"></iframe>
    <loading v-else>
    </loading>
    <router-view/>
  </div>
</template>

<script>
/* eslint-disable */
import loading from "../Loading/Loading.vue";
import { mapGetters } from "vuex";
import tools from "../../tools";
import Swal from "sweetalert2";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: 'Lesson',
  data() {
    return {
      iframeSrc: null,
      gelenDegerler: null,
      url: null,
      ready: false,
      isLoaded: false,
      isReady: false,
      isFit: true,
      isSuccess: false,
      state: null,
      styleFrame: {
        left: 0,
        transform: 0,
      },
      scormWidth: 1366,
      scormHeight: 768,
      userGrade: null,
    };
  },
  computed: {
    ...mapGetters(["getUserDetail"]),
  },
  methods: {
    fit() {
      this.isFit = !this.isFit;
      this.onResize();
    },
    onResize() {
      const contentWidth = this.$refs.content.offsetWidth;
      const contentHeight = this.$refs.content.offsetHeight - 50;
      let sizeContent = contentWidth;
      let sizeScorm = this.scormWidth;
      let scormWidth = this.scormWidth;
      let isWide = contentWidth / this.scormWidth > contentHeight / this.scormHeight;
      if (isWide) {
        if (this.isFit) {
          sizeContent = contentHeight;
          sizeScorm = this.scormHeight;
        } else {
          sizeScorm = this.scormWidth + 18;
          scormWidth += 18;
        }
      }
      const rate = sizeContent / sizeScorm;
      this.styleFrame.left = `${(contentWidth - (scormWidth * rate)) / 2}px`;
      this.styleFrame.transform = `scale(${rate})`;

    },
    fillResize(width, height) {
      if (!this.$refs.iframe) return;
      this.scormWidth = 1366;
      this.scormHeight = 768;
      this.$refs.iframe.style.width = `${this.scormWidth}px`;
      this.$refs.iframe.style.height = `${this.scormHeight}px`;
      this.onResize();
      this.isReady = true;
    },
    saveAndExit() {
      this.ready = true;
      setTimeout(() => {
        this.ready = false;
        this.$router.push("/");
      }, 1000);
    },
  },
  beforeMount() {
    this.ready = true;
    if (isMobileOnly) {
      // mobilden girişleri engelliyoruz!
      Swal.fire({
        title: "Uyarı",
        icon: "info",
        text: "Derse bilgisayar ya da tablet üzerinden giriş yapmanız tavsiye edilir.!",
      });
      // this.$router.push("/");
    }

    if (!this.$route.params.url) {
      this.$router.push("/");
    } else {
      let gelenDegerler = this.$route.params; //Route ile gelen parametreler
      // let user_id = window.btoa(gelenDegerler.user_id);
      let token = window.btoa(tools.cookie.get("access_token"));
      let link = this.$route.params.url;
      let unit_id = gelenDegerler.unit_id;
      let book_id = gelenDegerler.book_id;
      this.url = gelenDegerler.url;
      let books = this.getUserDetail.books.filter(x => x.bookID === book_id && x.unitID === unit_id);
      let isFirst = books.length > 0 ? window.btoa(false) : window.btoa(true);
      let lastPage = books.lastPage ? window.btoa(books.lastPage) : window.btoa(0);
      this.iframeSrc =
        link +
        "?" +
        token +
        ";" +
        isFirst +
        ";"+
        lastPage +
        ";";
      //   unit_id +
      //   ";" +
      //   book_id +
      //   ";" +
      //   name;
      // öğrencinin seviyesi
      this.userGrade = Number(this.getUserDetail.organizations.filter(i => i.type === "grade")[0]['name'].split("-")[0]);
      this.ready = false;
      this.isLoaded = true;
    }
  },
  mounted() {
    this.fillResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('message', this.onMessageReceived);
    clearTimeout(window.setTimeError);
  },
  components: { loading },
};
</script>

<style scoped>
#content {
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 top: 0;
	 left: 0;
	 overflow-x: hidden;
   background: #000;
}
 #content > nav {
	 background: #222222;
	 overflow: auto;
   padding: 1px 0px !important;
}
 #content > nav > .header {
	 display: inline-block;
}
 #content > nav > .menu > .sizing-btns, #content > nav > .menu .save-btn {
	 min-width: 168px;
	 margin: 5px;
	 padding: 8px;
	 color: #fff;
	 background-color: #007bff;
	 border-color: #fff;
	 border-radius: 3px;
	 font-size: 20px;
	 line-height: 20px;
	 float: left;
	 cursor: pointer;
   text-align: center;
}
 #content > nav > .menu > .sizing-btns:hover, #content > nav > .menu .save-btn:hover {
	 background-color: #2e93ff;
}
 #content > nav > .menu > .sizing-btns:active, #content > nav > .menu .save-btn:active {
	 box-shadow: 0 0 4px white;
	 background-color: #68b1ff;
}
 #content > nav > .menu > .save-btn {
	 float: right;
}
 #content > #iframe {
	 position: absolute;
	 /* border: 3px solid #fff;
	 border-radius: 5px; */
	 transform-origin: 0% 0%;
}
 #content > .loading {
	 position: fixed;
	 width: 100vw;
	 height: 100vh;
	 left: 0;
	 top: 0;
	 background-color: white;
}
 #content > .loading > .icon {
	 width: 30vh;
	 height: 30vh;
	 background-size: 100%;
	 animation: rot 0.5s linear infinite;
}
 @keyframes rot {
	 0% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(360deg);
	}
}

</style>
