<template>
  <nav class="py-4 bg-black">
    <div class="max-w-7xl h-full mx-auto px-2 lg:px-6">
      <div class="relative flex items-center justify-between">

        <div class="absolute inset-y-0 left-0 flex items-center lg:hidden">
          <button
              @click="mobileMenuToggle"
              class="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-1 focus:ring-inset focus:ring-white"
              aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        <div class="flex items-center justify-left lg:items-stretch lg:justify-start ml-12 lg:ml-0">
          <router-link :to="{name : 'home' }">
            <img
                class="h-16 w-auto rounded"
                src="@/assets/mentora-logo.jpg"
                alt="Mentora Logo" />
          </router-link>

          <div class="hidden lg:block lg:ml-20 lg:flex lg:items-center lg:space-x-6">
            <router-link
                v-if="getRole === 'student'"
                class="bg-pink-500 px-10 py-3 rounded text-white hover:bg-pink-600 text-sm font-bold"
                :to="{name : 'my-books' }"
            >My Books
            </router-link>
            <router-link
                v-if="getRole === 'teacher'"
                class="bg-pink-500 px-10 py-3 rounded text-white hover:bg-pink-600 text-sm font-bold"
                :to="{name : 'all-books' }"
            >All Books
            </router-link>
            <router-link
                v-if="getRole === 'teacher'"
                class="bg-pink-500 px-10 py-3 rounded text-white hover:bg-pink-600 text-sm font-bold"
                :to="{name : 'report' }"
            >Report
            </router-link>
          </div>

        </div>

        <div class="hidden lg:flex lg:items-center lg:inline-block">
          <div class="mr-10">
            <call-center />
          </div>
          <div class="font-bold text-pink-500">
            {{ getUserDetail.first_name + " " + getUserDetail.last_name }}
          </div>
          <a
              href="/#/logout"
              v-if="isAuthenticated"
              @click.prevent="logout"
              class="ml-4 font-bold text-black bg-gray-50 p-2 rounded hover:bg-pink-500 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
            </svg>
            Logout
          </a>
        </div>
      </div>
    </div>

    <div :class="mobileMenu ? 'block' : 'hidden'" class="lg:hidden">
      <div class="ml-4 flex flex-col text-left space-y-4 mt-4">
        <div class="font-bold text-pink-500">
          {{ getUserDetail.first_name + " " + getUserDetail.last_name }}
        </div>
        <router-link
            v-if="getRole === 'student'"
            :to="{ name : 'my-books' }"
            class="text-white hover:text-pink-500"
        >My Books
        </router-link>
        <router-link
            v-if="getRole === 'teacher'"
            :to="{ name : 'all-books' }"
            class="text-white hover:text-pink-500"
        >All Books
        </router-link>
        <router-link
            v-if="getRole === 'teacher'"
            :to="{ name : 'report' }"
            class="text-white hover:text-pink-500"
        >Report
        </router-link>
        <a
            href="/#/logout"
            v-if="isAuthenticated"
            @click.prevent="logout"
            class="text-white hover:text-pink-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
          </svg>
          Logout
        </a>
        <div class="mt-4">
        <call-center />
        </div>
      </div>
    </div>

  </nav>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import CallCenter from "@/components/CallCenter.vue";

export default {
  components: {CallCenter},
  data() {
    return {
      mobileMenu: false,
      userMenu: false,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getRole", "getUserDetail"]),
  },
  methods: {
    mobileMenuToggle() {
      this.mobileMenu = !this.mobileMenu;
    },
    logout() {
      Swal.fire({
        title: "Logout",
        text: "Are you sure you want to log out?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e74694",
        cancelButtonColor: "#999999",
        cancelButtonText: "Cancel",
        confirmButtonText: "Logout",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>
