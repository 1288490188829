<template>
  <div>
    <navbar></navbar>
    <loading v-if="ready"></loading>
    <div v-else class="container mx-auto mt-5 max-w-7xl z-10">

      <!-- parametre yoksa, sadece kitap kapakları listelenir -->
      <template v-if="!$route.query.book">
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 p-4">
          <div
              class="cursor-pointer flex justify-center"
              v-for="(book, i) in books"
              :key="i">
            <div class="w-96">
              <img
                  :src="book.cover"
                  @click="getUnits(book.id)"
                  class="shadow-lg border-4 border-gray-100 rounded-xl hover:border-pink-500"
                  :alt="book.title"/>
            </div>
          </div>
        </div>
      </template>

      <!-- parametre vardır, seçili kitabın üniteleri listelenir -->
      <template v-else-if="$route.query.book">

        <!-- seçili kitaptan geri dönme linki -->
        <div class="flex m-5">
          <button @click="goHome()" class="font-bold text-4xl text-blue-800 float-left bg-white mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
            </svg>
          </button>
          <h3 class="text-white p-2 font-bold text-xl text-left" v-if="selectedBook != null">
            {{ selectedBook.title }}
          </h3>
        </div>

        <!-- seçili kitabın üniteleri -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          <div
              class="p-3 border-2 border-opacity-60 border-white rounded-xl hover:border-opacity-100 hover:border-pink-400"
              v-for="(unit, i) in units"
              :key="i">
            <div class="w-full flex">
              <img
                  :src="unit.image"
                  :alt="unit.description"/>
            </div>
            <h5 class="text-white font-black text-xl text-center my-4"
                v-if="unit.description">
              {{ unit.description }}
            </h5>
            <div class="flex justify-center">
              <a href="/assigned-to"
                 class="bg-orange-500 text-white hover:bg-orange-700 px-2 py-1 rounded-md text-sm mr-3"
                 @click.prevent="openModal(unit)">
                Assign
              </a>
              <a
                  :href="unit.link"
                  class="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-md text-sm"
                  target="_blank">
                Preview Unit
              </a>
            </div>
          </div>
        </div>

      </template>

    </div>

    <book-class-modal
        @isModal="isModal = false"
        :isModal="isModal"
        :selectUnit="selectedUnit"
        :selectBook="selectedBook"
        :unit_id="selectedUnit"
    ></book-class-modal>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import Navbar from "../Navbar/Navbar.vue";
import loading from "../Loading/Loading.vue";
import BookClassModal from "./BookClassModal.vue";
import authServices from "../../services/auth";
import teacherServices from "../../services/teacher";
import _ from "lodash";

export default {
  components: {
    Navbar,
    loading,
    BookClassModal,
  },

  computed: {
    ...mapState(['userDetails', 'organizations']),
    ...mapGetters(['getUserDetail']),
  },

  data() {
    return {
      ready: false,
      units: [],
      books: [],
      serverSettings: null,
      organization: null,
      user_id: null,
      isModal: false,
      selectedUnit: null,
      selectedBook: null,
      classArr: [],
      // activeClasses: [],
    };
  },

  async beforeMount() {
    if (this.units.length === 0 && this.selectedBook != null) {
      this.$router.push("/books");
    }
    this.ready = true;
    this.books = [];
    this.units = [];
    const user = await authServices.getUser();
    const books = await teacherServices.getBooks();
    this.classArr = await teacherServices.getOrganizationBooks();
    this.books = books;
    this.units = _.uniqBy(this.books, function (e) {
      return e.id;
    });
    this.user_id = user.user_id;
    this.organization = user.organization;
    this.ready = false;
  },

  methods: {
    getUnits(value) {
      this.selectedBook = this.books.filter(x => x.id === value)[0];
      this.units = this.books.filter(x => x.id === value)[0].units;
      // this.activeClasses = this.classArr.filter(x => x.book_id === this.selectedBook.id && x.is_active === true);
      this.$router.push({name: 'all-books', query: {book: value}})
          .catch(err => {
            console.log(err)
          });
    },

    goHome() {
      this.$router.push({name: 'all-books'})
          .catch(err => {
            console.log(err)
          });
    },

    openModal(unit_id) {
      this.selectedUnit = unit_id;
      this.isModal = true;
    },

  },
};
</script>
