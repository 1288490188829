import mentora from '../services';
import uesLms from '../services/uesLms';
import tools from '../tools';
import store from '../store';
import config from '../config';

export default {
  async login(username, password) {
    try {
      const res = await mentora().post('/api/token', {
        username: username,
        password: password,
        api_key: config.apiKey,
      });

      if (res.message === 'Request failed with status code 401') {
        return {
          success: false,
        };
      }
      await tools.cookie.set(config.global_key.tokenName, res.data.token);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async loginSso(userData) {
    try {
      let user = null;

      if (userData.roleType.toLowerCase() === 'student'){
        user = await uesLms().post('/platformUserCreate', {
          school_id: userData.school_id,
          campus_name: userData.campus_name,
          first_name: userData.first_name,
          last_name: userData.last_name,
          username: userData.username,
          password: userData.password,
          role: userData.roleType,
          class: userData.class,
          branch: userData.branch,
          api_key: config.apiKey,
        });
      }
      else if (userData.roleType.toLowerCase() === 'teacher'){

        user = await uesLms().post('/platformUserCreate', {
          school_id: userData.school_id,
          campus_name: userData.campus_name,
          first_name: userData.first_name,
          last_name: userData.last_name,
          username: userData.username,
          password: userData.password,
          role: userData.roleType,
          classes: userData.classes,
          api_key: config.apiKey,
        });
      }
      if (user.data.message === 'Request failed with status code 401') {
        return {
          success: false,
        };
      }
      console.log('user.data.status_code', user.data.status_code)
      if (user.data.status_code !== 422) {
        // this.login(userData.username, userData.password)
        const res = await uesLms().post('/authenticate', {
          email: userData.username,
          password: userData.password,
          api_key: config.apiKey,
        });
        if (res.message === 'Request failed with status code 401') {
          return {
            success: false,
          };
        }
        await tools.cookie.set(config.global_key.tokenName, res.data.token);
        return {
          success: true,
          data: res.data,
        };
      }
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getUser() {
    const res = await mentora().post('/user');


    // // login olan öğretmenin sınıfları unique olarak alınıyor
    // const classArr = res.data.organizations.filter(x => x.type === 'grade');
    // let grades = [];
    // classArr.filter(function (s) {
    //   grades.push(s.name.split("-")[0]);
    // });
    // grades = grades.filter((x, i, a) => a.indexOf(x) === i)

    store.commit("setUserData", res.data);
    store.commit("setRole", res.data.role)
    // store.commit("setGrade", grades)
    return res.data;
  },
  // async getSettings() {
  //   const res = await mentora().get('settings/datetime');
  //   return res.data;
  // },
  // async refreshToken() {
  //   try {
  //     console.log("refff",store.state);
  //     const username = store.state.auth.username;
  //     const password = store.state.auth.password;
  //     const res = await mentora().post('authenticate', {
  //       email: username,
  //       password,
  //       api_key: 'PcRhne7I87fNcSrIFa800qvf2C77GWpiwgut1Amwri0kd9eq',
  //     });
  //     await tools.cookie.set("access_token", res.data.token);
  //     console.log("f5token",res.data.token);
  //     return {
  //       success: true,
  //       data: res.data,
  //     };
  //   } catch (error) {
  //     return {
  //       success: false,
  //       data: error.message,
  //     };
  //   }
  // },
};
