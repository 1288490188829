import Vue from "vue";
import Vuex from "vuex";
import tools from "../tools"

Vue.use(Vuex);

export default {
  state: {
      isAuthentication: null,
      role: null,
      expireTime: null,
      teacherId: null,
      token: null,
      userDetail: [],
      // grade: [],
      organizations: [],
  },
  getters: {
      isAuthenticated(state) {
          return state.isAuthentication
      },
      getToken(state) {
          return state.token;
      },
      getExpireTime(state) {
          return state.expireTime
      },
      getUserDetail(state) {
          return state.userDetail;
      },
      getRole(state) {
          return state.role;
      },
      // getGrade(state) {
      //     return state.grade;
      // },
      getOrganizations(state) {
          return state.organizations;
      },
  },
  mutations: {
      setAuthenticate(state, payload) {
          state.isAuthentication = payload
      },
      setToken(state,payload) {
        state.token = payload
      },
      setRole(state, payload) {
          state.role = payload
      },
      // setGrade(state, payload) {
      //     state.grade = payload
      // },
      setExpireTime(state, payload) {
          state.expireTime = payload
      },
      setUserData(state,payload){
          state.userDetail = payload
      },
      setOrganizations(state, payload) {
          state.organizations = payload
      },
  },
  actions: {
      logout({commit}) {
          commit("setAuthenticate", null);
          commit("setRole", null);
          // commit("setGrade", []);
          commit("setToken", null);
          commit("setUserData", null);
          tools.cookie.set("access_token", "", 0);
      }
  }
}
