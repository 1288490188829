module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#6f2b8f',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'PcRhne7I87fNcSrIFa800qvf2C77GWpiwgut1Amwri0kd9eq',
};
