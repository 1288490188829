import axios from 'axios';
import tools from '../tools';
import router from '../router';
import store from '../store';
const instance = axios.create();

instance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    let token = tools.cookie.get("access_token");
    config.headers.Authorization = `Bearer ${token}`;
    // config.baseURL = 'http://localhost:3332';
    config.baseURL = 'https://mentoralms-api.ey.r.appspot.com';
    return config;
});

instance.interceptors.response.use(function (response) {
    // Gelen Status 401 ise logine yönlendir!
    return response
}, function (error) {
    // console.log(error.response.data)
    if (error.response.status === 401) {
        store.dispatch('logout')
        router.push('/login')
    }
    return Promise.reject(error)
})

export default () => instance;
