import axios from 'axios';
import tools from '../tools';
const instance = axios.create();

instance.interceptors.request.use((config) => {
    let token = tools.cookie.get("access_token");
    config.baseURL = 'https://www.ueslms.com/api/v2';
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

instance.interceptors.response.use(res => res, err => err);

export default () => instance;
