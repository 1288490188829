<template>
  <div>
    <navbar></navbar>
    <loading v-if="ready"></loading>
    <div v-else class="container mx-auto mt-5 max-w-7xl z-10">

      <!-- parametre yoksa, sadece kitap kapakları listelenir -->
      <template v-if="!$route.query.book">
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 p-4">
          <div
              class="cursor-pointer flex justify-center"
              v-for="(book, i) in books"
              :key="i">
            <div class="w-96">
              <img
                  :src="book.cover"
                  @click="getUnits(book.id)"
                  class="shadow-lg border-4 border-gray-100 rounded-xl hover:border-pink-500"
                  :alt="book.title"/>
            </div>
          </div>
        </div>
      </template>

      <!-- parametre vardır, seçili kitabın üniteleri listelenir -->
      <template v-else-if="$route.query.book">

        <!-- seçili kitaptan geri dönme linki -->
        <div class="flex m-5">
          <button @click="goHome()" class="font-bold text-4xl text-blue-800 float-left bg-white mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
            </svg>
          </button>
          <h3 class="text-white p-2 font-bold text-xl text-left">
            {{ selectedBook.title }}
          </h3>
        </div>

        <!-- 'Theme 1' ifadesi -->
        <!-- k12 tarafında gruplama için kullanılıyor -->
        <div v-if="$route.query.book && getDescriptionTheme(units[0].description)"
             class="grid grid-cols-1 gap-4 p-4">
          <h1 class="w-full text-2xl text-white p-3 rounded-md bg-orange-500 border-8 border-white font-bold shadow-xl text-center">
            Theme 1:
            {{ getDescriptionTheme(units[0].description) }} </h1>
        </div>

        <!-- seçili kitabın üniteleri: part 1 0-3 -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          <div
              v-for="(unit, i) in units.slice(0, 3)"
              :key="i"
              class="p-3 border-2 border-opacity-60 border-white rounded-xl relative"
              :class="`${initUnit(unit) ? 'cursor-pointer hover:border-opacity-100 hover:border-pink-400' : 'cursor-not-allowed'}`"
              @click="initUnit(unit) ? goLesson(unit) : ''">
            <!--            <div-->
            <!--                v-if="getScore($route.query.book, unit.id)&& initUnit(unit)"-->
            <!--                class="absolute bg-red-600 w-12 h-12 rounded-full text-white -right-2 -top-2 flex items-center justify-center border-4 border-white shadow-xl text-lg font-bold">-->
            <!--              {{ getScore($route.query.book, unit.id) }}-->
            <!--            </div>-->
            <div class="w-full">
              <img
                  :src="unit.image"
                  :class="initUnit(unit) ? '' : 'filter grayscale'"
                  :alt="unit.description"/>
            </div>
            <h5 class="text-white font-black text-xl text-center my-4"
                v-if="unit.description">
              {{ getDescription(unit.description) }}
            </h5>
            <div v-if="userGrade === 99">
              <div
                  class="bg-white p-2 rounded-lg"
                  v-if="getScore($route.query.book, unit.id) && initUnit(unit)">
                <div class="grid grid-cols-6">
                  <div class="col-span-4 h-18 px-2 py-2 text-left bg-blue-700 rounded-l-lg bg-blue-700">
                    <label class="text-white text-sm ml-5">You answered</label><br>
                    <label class="text-white text-xl ml-5">{{ getScore($route.query.book, unit.id)['user'] + ' of ' + getScore($route.query.book, unit.id)['total'] }} correctly</label>
                  </div>
                  <div class="col-span-2 h-18 px-2 py-2 text-center bg-blue-900 rounded-r-lg bg-blue-900">
                    <label class="text-white text-xl">{{ Math.floor(getScore($route.query.book, unit.id)['user'] * 100 / getScore($route.query.book, unit.id)['total']) }}%</label><br>
                    <label class="text-white text-sm">Completed</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 'Theme 2' ifadesi -->
        <!-- k12 tarafında gruplama için kullanılıyor -->
        <div v-if="$route.query.book && getDescriptionTheme(units[3].description)"
             class="grid grid-cols-1 gap-4 p-4">
          <h1 class="w-full text-2xl text-white p-3 rounded-md bg-orange-500 border-8 border-white font-bold shadow-xl text-center">
            Theme 2:
            {{ getDescriptionTheme(units[3].description) }} </h1>
        </div>

        <!-- seçili kitabın üniteleri: part 2 3-99 -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          <div
              v-for="(unit, i) in units.slice(3)"
              :key="i"
              class="p-3 border-2 border-opacity-60 border-white rounded-xl relative"
              :class="`${initUnit(unit) ? 'cursor-pointer hover:border-opacity-100 hover:border-pink-400' : 'cursor-not-allowed'}`"
              @click="initUnit(unit) ? goLesson(unit) : ''">
            <!--
            <div
                v-if="getScore($route.query.book, unit.id)&& initUnit(unit)"
                class="absolute bg-red-600 w-12 h-12 rounded-full text-white -right-2 -top-2 flex items-center justify-center border-4 border-white shadow-xl text-lg font-bold">
              {{ getScore($route.query.book, unit.id) }}
            </div>
            -->
            <div class="w-full">
              <img
                  :src="unit.image"
                  :class="initUnit(unit) ? '' : 'filter grayscale'"
                  :alt="unit.description"/>
            </div>
            <h5 class="text-white font-black text-xl text-center my-4"
                v-if="unit.description">
              {{ getDescription(unit.description) }}
            </h5>
            <div v-if="userGrade === 99">
              <div
                  class="bg-white p-2 rounded-lg"
                  v-if="getScore($route.query.book, unit.id) && initUnit(unit)">
                <div class="grid grid-cols-6">
                  <div class="col-span-4 h-18 px-2 py-2 text-left bg-blue-700 rounded-l-lg bg-blue-700">
                    <label class="text-white text-sm ml-5">You answered</label><br>
                    <label class="text-white text-xl ml-5">{{ getScore($route.query.book, unit.id)['user'] + ' of ' + getScore($route.query.book, unit.id)['total'] }} correctly</label>
                  </div>
                  <div class="col-span-2 h-18 px-2 py-2 text-center bg-blue-900 rounded-r-lg bg-blue-900">
                    <label class="text-white text-xl">{{ Math.floor(getScore($route.query.book, unit.id)['user'] * 100 / getScore($route.query.book, unit.id)['total']) }}%</label><br>
                    <label class="text-white text-sm">Completed</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>


    </div>
  </div>
</template>

<script>
import Navbar from "../Navbar/Navbar.vue";
import loading from "../Loading/Loading.vue";
import authServices from "../../services/auth";
import studentServices from "../../services/student";
import {mapGetters} from "vuex";

export default {
  components: {
    Navbar,
    loading
  },

  computed: {
    ...mapGetters(["getRole", "getUserDetail", "getOrganizations"]),
  },

  data() {
    return {
      user_id: null,
      organization: null,
      books: [],
      units: [],
      units2: [],
      selectedBook: null,
      serverSettings: null,
      ready: false,
      userGrade: null,
    };
  },

  async mounted() {
    this.ready = true;
    await authServices.getUser();
    const user = this.getUserDetail;
    this.books = await studentServices.getBooks();
    this.user_id = user.uesLms_Id;
    this.organization = user.organization;
    this.getScore();
    if (this.$route.query.book != null) {
      var bookId = this.$route.query.book
      this.selectedBook = this.books ? this.books.filter(x => x.id === bookId)[0] : '';
      this.units = this.books ? this.books.filter(x => x.id === bookId)[0].units : '';
    }
    this.ready = false;
    // öğrencinin seviyesi
    this.userGrade = Number(this.getUserDetail.organizations.filter(i => i.type === "grade")[0]['name'].split("-")[0]);
  },

  methods: {
    getUnits(value) {
      this.selectedBook = this.books ? this.books.filter(x => x.id === value)[0] : '';
      this.units = this.books ? this.books.filter(x => x.id === value)[0].units : '';
      this.$router.push({name: 'my-books', query: {book: value}}).catch(err => {
      });
    },

    goLesson(attr) { // Ders görüntüleme ekranına git!
      const routerObj = {
        name: "lesson",
        params: {
          url: attr.link,
          user_id: this.user_id,
          unit_id: attr.id,
          book_id: this.selectedBook.id,
          name: attr.name,
        },
      };
      this.$router.push(routerObj);
    },

    initUnit(unit) { // Unit kontrolleri
      const organizations = this.getOrganizations.filter(x => x.book_id === this.selectedBook.id && x.unit_id === unit.id);
      if (organizations.length > 0) {
        return organizations[0].is_active;
      } else {
        return false;
      }
    },

    getScore(book_id, unit_id) {
      const result = this.getUserDetail.books.filter(t => t.bookID === book_id && t.unitID === unit_id);
      let score = [];

      if (result.length > 0) {
        score['total'] = result[0].unitTotalItemCount;
        score['user'] = result[0].unitTotalTrueItemCount;
        return score;
      } else {
        return null;
      }

      // const score = this.getUserDetail.books.filter(x => x.bookID === book_id && x.unitID === unit_id);
      // if (score.length > 0) {
      //   return Math.floor(score[0].score);
      // } else {
      //   return null;
      // }
    },

    getDescription(val) {
      return val.split("-")[0];
    },

    getDescriptionTheme(val) {
      return val.split("-")[1];
    },

    goHome() {
      this.$router.push({name: 'my-books'})
          .catch(err => {
            console.log(err)
          });
    }
  },
};
</script>
