<template>
  <div>
    <loading v-if="ready"></loading>

    <div class="w-full h-screen flex">
      <img src="@/assets/auth-image.jpg" alt="login background"
           class="filter contrast-125 object-cover object-center h-screen md:w-7/12 md:block hidden">

      <div
          class="bg-gradient-to-r from-purple-900 to-purple-500 flex flex-col justify-center items-center w-full md:w-5/12">

        <div class="max-w-md w-full space-y-8 bg-white p-8 rounded-2xl shadow-2xl">

          <img class="mx-auto w-72" src="@/assets/mentora-logo.jpg" alt="Mentora Logo">

          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="username" class="sr-only">Username</label>
              <input
                  v-model="username"
                  name="username"
                  type="text"
                  autocomplete="username"
                  required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-pink-500 focus:border-pink-500 focus:z-10"
                  placeholder="Username">
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input
                  v-model="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-pink-500 focus:border-pink-500 focus:z-10"
                  placeholder="Password">
            </div>
          </div>

          <button
              @click.prevent="login()"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent font-bold rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                   aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            Login
          </button>

        </div>

        <div class="max-w-md w-full flex items-center justify-center mt-8">
          <call-center />
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import Swal from 'sweetalert2';
import StudentServices from '@/services/student';
import AuthServices from '@/services/auth';
import loading from '@/components/Loading/Loading.vue';
import CallCenter from '@/components/CallCenter.vue';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

export default {
  data() {
    return {
      username: null,
      password: null,
      ready: false,
    };
  },
  components: {
    loading,
    CallCenter
  },
  computed: {},
  async beforeMount() {
    this.autoLogin();
  },
  methods: {
    async login() {
      this.ready = true;
      const user = await AuthServices.login(this.username, this.password);

      if (user.data.token) {
        await AuthServices.getUser();
        this.setLoginState(user.data.token, user.data);
        return;
      }
      Swal.fire(
          'Error!',
          'The username or password is incorrect.',
          'error'
      )
      this.ready = false;
    },
    async setLoginState(token, user) {
      const role = user.role;
      this.$store.commit("setAuthenticate", true);
      this.$store.commit("setToken", token);
      this.$store.commit("setRole", role);
      if (role === "student") {
        const organizationsBook = await StudentServices.getOrganizationBooks();
        this.$store.commit('setOrganizations', organizationsBook);
      }
      this.$router.push("/");
    },
    async autoLogin() {
      this.ready = true;
      const arr = this.$route.query;
      let jsonArr = {};
      if (_.isEmpty(arr) === false) {
        if (arr.sso) {
          const decode = decodeURIComponent(atob(arr.sso));
          const secret = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
          const bytes = CryptoJS.AES.decrypt(decode, secret).toString(CryptoJS.enc.Utf8);
          jsonArr = JSON.parse(bytes);
          if (jsonArr.roleType.toLowerCase() === 'teacher') {
            jsonArr.roleType = 'teacher';
            jsonArr.password = 'Ues1234!';
          } else {
            jsonArr.password = '123456';
            jsonArr.roleType = 'student';
          }
        } else {
          jsonArr.username = atob(arr.username);
          jsonArr.password = atob(arr.password);
        }
        try {
          const user = await AuthServices.login(jsonArr.username, jsonArr.password);
          if (user.success) {
            await AuthServices.getUser();
            this.setLoginState(user.data.token, user.data);
            this.ready = false;
            return;
          } else {
            const login = await AuthServices.loginSso(jsonArr);
            if (login.data.token) {
              const user = await AuthServices.login(jsonArr.username, jsonArr.password);
              await AuthServices.getUser();
              this.setLoginState(login.data.token, user.data);
              return;
            }
            this.ready = false;
          }
        } catch (error) {
          this.ready = false;
          console.log(error)
        }
      } else {
        this.ready = false;
      }
    },
  }
}
</script>
