import api from './index';

export default {
  async getList(teacherId) {
    const res = await api().post('teacher/list', {
      teacherId,
    });
    return res.data;
  },

  async getBooks() {
    const res = await api().post('/book/allBooks');
    // const res = await api().post('/book/all');
    return res.data;
  },

  async addOrganizationBook(class_id, book_id, unit_id, is_active) {
    const res = await api().post('/book/addOrganizationBooks', {
      class_id,
      book_id,
      unit_id,
      is_active
    });
    return res.data;
  },

  async getOrganizationBooks() {
    const res = await api().post('/book/getOrganizationBooks');
    return res.data;
  },

  async getOrganizationStudents(class_id, book_id, unit_id) {
    const res = await api().post('/user/organizationStudents', {
      classID: class_id,
      bookID: book_id,
      // unitID: unit_id,
    });
    return res.data;
  },

  async getClassReport(class_id, exam_id) {
    const res = await api().post('exams/report/class',{
      class_id,
      exam_id
    });
    return res.data;
  },

  async getStudentReport(student_id, exam_id) {
    const res = await api().post('exams/report/student',{
      student_id,
      exam_id
    });
    return res.data;
  },

  async addStudent(student) {
    const res = await api().post('teacher/add', {
      fullname: student.fullname,
      username: student.username,
      password: student.password,
      teacherId: student.teacherId,
      level: student.level,
    });
    return res.data;
  },

};
