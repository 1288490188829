<template>
  <div></div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["getRole"]),
  },
  async beforeMount() {
    this.ready = true;
    if (this.getRole === "teacher") {
      this.$router.push("/all-books");
    } else {
      this.$router.push("/my-books");
    }
    this.ready = false;
  },
};
</script>
