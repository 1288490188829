import api from './index';

export default {
  async getBooks(){
    // const res = await api().post('/book/all');
    const res = await api().post('/book/allBooks');
    return res.data;
  },

  async getOrganizationBooks(){
    const res = await api().post('/book/getOrganizationBooks');
    return res.data;
  },
};
