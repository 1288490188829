<template>

  <div>
    <loading v-if="ready"></loading>
    <div class="fixed z-10 inset-0 mx-2 md:mx-20 sm:mx-w- overflow-y-auto" v-if="isModal === true && ready === false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm: w-6/6"
            role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="container mx-auto px-4 mt-10">
            <div class="grid grid-cols-1 lg:grid-cols-1">
              <h1 class=" bg-white shadow-md text-center p-2 text-2xl">
                <b>{{ selectBook.title }} </b> / {{ selectUnit.name }}
              </h1>
              <br>
              <div class="w-full mb-1" v-if="selectUnit.image">
                <img :src="selectUnit.image" alt="" width="100%" style="height:100%">
              </div>
              <h5 class="unitDescription" v-if="selectUnit.description">{{ selectUnit.description }}</h5>
              <ul v-if="ready === false" class="text-center">
                <p class="text-lg mt-5 bg-gray-300 leading-loose font-bold pt-2" for="all">
                  <label for="all">
                  <input type='checkbox' @click='checkAll()' v-model='isCheckAll' id="all" class="w-4 h-4">
                  Check All
                  </label>
                </p>
                <li
                    v-for="(classes,i) in classArr"
                    :key="i"
                    class="text-lg mt-0 bg-gray-300 leading-loose font-bold pt-0">
                  <label :for="`class${classes.id}`">
                    <input
                        type="checkbox"
                        class="w-4 h-4"
                        v-model="classes.is_active"
                        @change='updateCheckall()'
                        name="classes"
                        :id="`class${classes.id}`">
                    {{ classes.name }}
                  </label>
                </li>
              </ul>
              <br>
              <button @click="updateRole()" class="bg-pink-500 text-white w-48 mx-auto px-4 py-2 font-bold relative rounded-md">
                Update
              </button>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="closeClick()" type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading/Loading.vue';
import {mapGetters} from "vuex";
import teacherServices from '@/services/teacher';
import Swal from 'sweetalert2';

export default {
  components: {
    Loading
  },
  props: ['isModal', 'selectUnit', 'unit_id', 'selectBook'],
  data() {
    return {
      result: [],
      ready: false,
      listening: null,
      reading_writing: null,
      selectedClasses: [],
      classArr: [],
      newClassArr: [],
      isCheckAll: false,
    }
  },
  computed: {
    ...mapGetters(['getUserDetail']),
  },
  async mounted() {
    this.ready = true;
    this.selectedClasses = [];
    this.ready = false;
  },
  watch: {
    async isModal(val) {
      this.ready = true;
      if (val === true) {
        this.selectedClasses = [];
        await this.updateClasses();
      }
      this.ready = false;
    },
  },
  methods: {
    closeClick() {
      this.ready = true;
      this.classArr = [];
      this.$emit('isModal', false);
      this.ready = false;
    },

    async updateRole() {
      this.ready = true;
      await this.classArr.forEach(element => {
        teacherServices.addOrganizationBook(element.id, this.selectBook.id, this.selectUnit.id, element.is_active);
      });
      this.$emit('activeClasses', true);
      Swal.fire(
          'Başarılı!',
          'Ünite durumu düzenlendi',
          'success'
      );
      this.ready = false;
    },

    async updateClasses() {
      this.ready = true;
      const organizationsBook = await teacherServices.getOrganizationBooks();
      this.classArr = this.getUserDetail.organizations.filter(x => x.type === 'grade');
      const organizationsGrade = this.selectBook.grade;
      this.classArr = this.classArr.filter(function (s) {
        const gradeNum = s.name.split("-")[0];
        if (Number(gradeNum) === Number(organizationsGrade)) {
          return s;
        } else {
          return false;
        }
      });

      this.classArr.forEach(element => {
        element.test = this.newClassArr.length;
        const newClassArr = organizationsBook.filter(x => x.unit_id === this.selectUnit.id && x.book_id === this.selectBook.id && x.class_id === element.id);
        element.is_active = newClassArr.length > 0 ? newClassArr[0].is_active : false;
      });

      var say = 0;
      this.classArr.forEach(element => {
        if (element.is_active === true) {
          say++;
        }
      });

      this.isCheckAll = say === this.classArr.length;
      this.ready = false;
    },

    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.selectedClasses = [];
      if (this.isCheckAll) {
        this.classArr.forEach(element => {
          element.is_active = true;
        });
      } else {
        this.classArr.forEach(element => {
          element.is_active = false;
        });
      }
    },

    updateCheckall() {
      this.ready = true;
      var say = 0;
      this.classArr.forEach(element => {
        if (element.is_active === true) {
          say++;
        }
      });

      this.isCheckAll = say === this.classArr.length;
      this.ready = false;
    }

  }
}
</script>
<style>
.unitDescription {
  text-align: center;
  color: #0b50c3;
  font-weight: 800;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
